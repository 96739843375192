<template>
  <v-expansion-panel
    v-model="billingAddress"
    focusable
    popout
    expand
    class="elevation-0"
  >
    <v-expansion-panel-content ripple>
      <div slot="header">
        <v-layout row>
          <v-icon color="indigo lighten-2">
            {{ checked ? 'check_box' : 'check_box_outline_blank' }}
          </v-icon>
          <span>&nbsp;&nbsp;Different billing address</span>
        </v-layout>
      </div>
      <v-card>
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-container
              fluid
              class="pa-0"
            >
              <v-layout
                v-for="(thisField, i) in fields"
                :key="i"
                column
              >
                <v-flex>
                  <component
                    :is="thisField.component"
                    :field="thisField"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  props: {
    field: {
      required: true,
      type: Object,
    },
  },
  data () {
    return {
      billingAddress: [false],
      valid: true,
    }
  },
  computed: {
    checked: function () {
      return this.billingAddress && this.billingAddress[0]
    },
    fieldCount: function () {
      return Object.keys(this.fields).length
    },
    fields: function () {
      return this.field.fields
    },
  },
  watch: {
    billingAddress: function (val) {
      const field = this.field
      field.value = this.checked
      this.$emit('update:field', field)
    },
  },
  mounted: function () {
    this.$set(this.field, 'validate', val => {
      return !this.billingAddress[0] || this.$refs.form.validate()
    })
  },
}
</script>
